import React from "react";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import { useDispatch, useSelector } from "redux/hooks";
import { REQUEST_LOCATION } from "redux/reducers/location";
import { getFormattedLocation } from "redux/selectors/location";

type Props = {
  subtitle: string;
  changeLocation: boolean;
  title: string;
  referrer?: string | null;
};
const Subtitle = ({
  subtitle,
  title,
  changeLocation,
  referrer = null,
}: Props) => {
  const dispatch = useDispatch();
  const formattedLocation = useSelector(getFormattedLocation);
  const { publishEvent } = useEventTracker();

  const onClickHandler = () => {
    dispatch({ type: REQUEST_LOCATION });

    publishEvent({
      action: Action.click,
      category: Category.carousel,
      label:
        referrer === "strainDispensaryCarousel"
          ? "strain page dispensary carousel change location"
          : title,
    });
  };

  return (
    <>
      {subtitle}
      {changeLocation && (
        <>
          {" "}
          <button
            onClick={onClickHandler}
            className="font-bold pointer text-green underline"
            data-testid="section-wrapper-location-change"
          >
            {`${formattedLocation}` || "your city"}
          </button>
        </>
      )}
    </>
  );
};

export default Subtitle;
